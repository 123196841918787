.ant-drawer .ant-drawer-content {
	width: 260px;
	background: rgba(255, 255, 255, 0.75);
}
.ant-drawer-content-wrapper {
	width: 260px !important;
	background: rgba(255, 255, 255, 0.75);
}
.ant-drawer-header-close-only {
	display: none;
}
.anticon svg {
	font-size: 18px;
}
a {
	color: #666;
	font-size: 15px;
	font-weight: 100;
}
